import React from 'react' 
import L1000945 from '../../static/images/ask-logos/ssax80.png'
import L1000946 from '../../static/images/ask-logos/SavingsPlusx80.png'
import L1000947 from '../../static/images/ask-logos/the-california-state-university-logo.png'
import L1000948 from '../../static/images/ask-logos/ScholarShare529x80.png'
import L1000949 from '../../static/images/ask-logos/1000930.png'
import L1000950 from '../../static/images/ask-logos/1000931.png'

import L1000952 from '../../static/images/ask-logos/top-logo-abc.png'
import L1000953 from '../../static/images/ask-logos/Blue Shieldx80.png'
import L1000954 from '../../static/images/ask-logos/healthnetx80.png'
import L1000955 from '../../static/images/ask-logos/KPx80.png'
import L1000956 from '../../static/images/ask-logos/Optumx80.png'
import L1000957 from '../../static/images/ask-logos/SHPx80.png'
import L1000958 from '../../static/images/ask-logos/UHCx80.png'
import L1000959 from '../../static/images/ask-logos/WHAx80.png'
import L1000984 from '../../static/images/ask-logos/1000984.png'


import PERSLOGO from '../../static/images/ask-logos/2560px-CalPERS_logo.png'

import RPEALogo  from '../../static/images/ask-logos/RPEA-logo.png'

import { StaticImage } from "gatsby-plugin-image"; 

export function ReturnAskTheExpertsLogo({id, title, className}) {

    switch(title) {
      case "Retired Public Employees' Association (RPEA)": 
      return <img
            src={RPEALogo} 
            style={{ opacity: "1", transform: "none" }} className={className}
            alt={title ? title : ''}
          />
      case "HICAP - California Health Advocates":
            return <img
            src={L1000984} 
            style={{ opacity: "1", transform: "none" }} className={className}
            alt={title ? title : ''}
          />
      case "Social Security Administration (SSA)":
            return <img
            src={L1000945}
            style={{ opacity: "1", transform: "none" }} className={className}
            alt={title ? title : ''}
          />
        case "Savings Plus for California State Employees":
            return <img
            src={L1000946} 
            style={{ opacity: "1", transform: "none" }} className={className}
            alt={title ? title : ''}
          />

          case "CSU System Wide Benefits Office - For CSU Employees":
            return <img
            src={L1000947} 
            style={{ opacity: "1", transform: "none" }} className={className}
            alt={title ? title : ''}
          />
          case "ScholarShare 529 – California's 529 College Savings Plan": case "ScholarShare 529 - California's 529 College Savings Plan":         
            return <img
            src={L1000948} 
            style={{ opacity: "1", transform: "none" }} className={className}
            alt={title ? title : ''}
          />
          case "California School Employees Association (CSEA)":
            return <img
            src={L1000949} 
            style={{ opacity: "1", transform: "none" }} className={className}
            alt={title ? title : ''}
          />
          case "California State Retirees":
            return <img
            src={L1000950} 
            style={{ opacity: "1", transform: "none" }} className={className}
            alt={title ? title : ''}
          />
        
          case "Health Plan - Anthem Blue Cross":
            return <img
            src={L1000952} 
            style={{ opacity: "1", transform: "none" }} className={className}
            alt={title ? title : ''}
          />
          case "Health Plan - Blue Shield":
            return <img
            src={L1000953} 
            style={{ opacity: "1", transform: "none" }} className={className}
            alt={title ? title : ''}
          />
          case "Health Plan - Health Net":
            return <img
            src={L1000954} 
            style={{ opacity: "1", transform: "none" }} className={className}
            alt={title ? title : ''}
          />
          case "Health Plan - HealthNet":
            return <img
            src={L1000954} 
            style={{ opacity: "1", transform: "none" }} className={className}
            alt={title ? title : ''}
          />
          case "Health Plan - Kaiser Permanente":
            return <img
            src={L1000955} 
            style={{ opacity: "1", transform: "none" }} className={className}
            alt={title ? title : ''}
          />
          case "Health Plan - OptumRx": case "Health Plan - Optum Rx":
            return <img
            src={L1000956} 
            style={{ opacity: "1", transform: "none" }} className={className}
            alt={title ? title : ''}
          />
                
      case "Health Plan - Sharp Health Plan":
        return <img
        src={L1000957} 
        style={{ opacity: "1", transform: "none" }} className={className}
        alt={title ? title : ''}
      />
        case "Health Plan - UnitedHealthcare":
            return <img
            src={L1000958} 
            style={{ opacity: "1", transform: "none" }} className={className}
            alt={title ? title : ''}
          />

          case "Health Plan - Western Health Advantage":
            return <img
            src={L1000959} 
            style={{ opacity: "1", transform: "none" }} className={className}
            alt={title ? title : ''}
          />

      

        case "CalPERS Regional Offices: General Benefits Information": case "CalPERS Service Credit Purchase": case "CalPERS Deferred Compensation Plans": case "CalPERS Health Benefits Program": case "CalPERS Community Property": case "CalPERS Retirement Benefits":
            return <img
            src={PERSLOGO} 
            style={{ opacity: "1", transform: "none" }} className={className}
            alt={title ? title : ''}
          />

       
        default:
            return ""
    }
}