import React, { useState, useEffect } from "react";
import { Tab } from "@headlessui/react";
import { Dialog } from "@headlessui/react";
import { RadioGroup, Listbox, Transition } from "@headlessui/react";
import {useLocalStorage} from '../hooks/useLocalStorage'
import { CheckCircleIcon, CheckIcon, XIcon } from "@heroicons/react/solid";
import Button from "./Atoms/Button";
import { returnAMPMBasedOnStartEndDate, returnAMPMBasedOnDate } from "../helpers/dates";
import {careerStages} from '../helpers/careerStages'
import { useLocalStorage as useMantineLocalStorage} from '@mantine/hooks';

import { Link } from 'gatsby';

import { indexOfCurrentDayOrLastItem } from "../helpers/indexOfCurrentDayOrLastItem";

const dayjs = require("dayjs");
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone') // dependent on utc plugin

dayjs.extend(utc)
dayjs.extend(timezone)

const pacificTimeZone = 'America/Los_Angeles'
  

function ScheduleRegisteredUser({ autoSelectUpcomingClassDays, scheduleData, enrolledClasses, isPublicView, schedulePref, changeSchedulePref}) {
 
  const [selectedClassDayFromStorage, setSelectedClassDayFromStorage] = useMantineLocalStorage({
    key: 'selectedClassDay', 
  });

  let [modalIsOpen, setModalIsOpen] = useState(false);
  let [modalSelectedSession, setModalSelectedSession] = useState(null); 
  const [selectedCareerStages, setSelectedCareerStages] = useLocalStorage('careerStageSelection', '')
  const [registrationInfo] = useLocalStorage('registration', '')

 let format = "h:mm"
 

 useEffect(()=>{
  if(registrationInfo && registrationInfo.career_stage_code && selectedCareerStages && selectedCareerStages.length < 1 ){
    setSelectedCareerStages([registrationInfo.career_stage_code]) 
  }
  if(isPublicView) {
    setSelectedCareerStages(["RET","ERL"])
  }

  //cleanup, on unmount, remove the double selection for public view
  return () => {
    if (isPublicView) {
      setSelectedCareerStages([])
    }
  }
 
 },[])

  const ReturnListOfEventByDayAndTrack = ({
    tab,
    date,
    events,
    sessionTypeCode,
    sessionCat,
    color
  }) => {

    // First sort the list by sessionName case insensitive
    let eventDaySessionsSorted = events.eventDaySessions.sort((a, b) => ( a.eventSession.eventSessionName.toLowerCase() < b.eventSession.eventSessionName.toLowerCase() ? 1 : -1))
    // Finally sort the pre-sorted list by sessionStartDttm
    eventDaySessionsSorted = eventDaySessionsSorted.sort((a, b) => (a.sessionStartDttm > b.sessionStartDttm? 1: -1))

    let prevEventStartDateTime;
    
    return (
      <ul>
        {eventDaySessionsSorted.map((event, idx) => {
            const { eventSession } = event;
 
            let enrolled = false
            let enrolledText =  "Learn More & Enroll" 

            if(!isPublicView) {
              enrolledClasses.forEach(element => {
                if (event.id == element) {
                  enrolled = true
                }
              })
            }


            //if the event is over, drop the :and enroll: from the btn 
            if(dayjs(event.sessionEndDttm).diff(dayjs(), 'seconds') < 1) {
              enrolledText = "Learn More"
            }
 
            let codeColor = careerStages[careerStages.map(e => e.code).indexOf(eventSession.sessionCategory.code)].color

            // && (new Date(event.sessionStartDttm).getDate() == date ) 

 

            
            if ((selectedCareerStages && selectedCareerStages.includes(eventSession.sessionCategory.code) || eventSession.sessionCategory.code == "ALL") && eventSession.sessionType.code == sessionTypeCode) {
              
              
              let currentAndPrevEventStartAtSameTime = false
              if(event.sessionStartDttm == prevEventStartDateTime){
                currentAndPrevEventStartAtSameTime = true
              }
              prevEventStartDateTime = event.sessionStartDttm
    
              return (
                <li key={event.id}>
                {currentAndPrevEventStartAtSameTime ?  null :  <span className="block font-bold text-gray-900">{dayjs(event.sessionStartDttm).format(format)}{dayjs(event.sessionStartDttm).format("H") > 11 && dayjs(event.sessionStartDttm).format("H") > 11 ? ' p.m.' : ' a.m.'}</span>}
               <ul>
                <li className="px-4 md:px-12">
                 <div
                  style={{borderLeftColor: codeColor}}
                  className={`border-l-4 p-8 py-6 bg-white shadow shadow rounded-md my-4 pr-6 ${enrolled ? "" : ""
                    }`}
                > 
         {!isPublicView && enrolled ? <div className="inline-flex mb-3 items-center p-2 px-4 rounded-full bg-gray-50"><CheckCircleIcon className="text-green-600 w-6 h-6 mr-1" /><span className="text-green-600 font-semibold text-sm">You are enrolled</span></div> : null}

                <div className="flex justify-between items-center">

            
                <p className="text-gray-600">
                   
                    {dayjs(event.sessionStartDttm).tz(pacificTimeZone).format(format)}{returnAMPMBasedOnStartEndDate(event.sessionStartDttm,event.sessionEndDttm, undefined, pacificTimeZone)[0]} -{" "}
                    {dayjs(event.sessionEndDttm).tz(pacificTimeZone).format(format)}{returnAMPMBasedOnStartEndDate(event.sessionStartDttm,event.sessionEndDttm, undefined, pacificTimeZone)[1]}  (PT)
                   
                  </p>
                  <div className="flex items-center font-medium" style={{color: codeColor}}>
                    <div className="h-4 w-4 mr-2 rounded-full" style={{backgroundColor: codeColor}}/>
                    {eventSession.sessionCategory && eventSession.sessionCategory.code === 'ALL'? 'All Career Stages':eventSession.sessionCategory.displayName} </div>
                   </div>
                 
                  <div className="py-3">
                    {!isPublicView ? <Link title={enrolled ? 'Learn more about ' + 
                          eventSession.eventSessionName + 
                          ' class on ' + 
                          dayjs(event.sessionStartDttm).format("dddd") + 
                          ' at ' +
                          dayjs(event.sessionStartDttm).format(format) + ' ' + returnAMPMBasedOnDate(event.sessionStartDttm)
                          : 
                          'Learn more and enroll in ' + 
                          eventSession.eventSessionName + 
                          ' class on ' + 
                          dayjs(event.sessionStartDttm).format("dddd") + 
                          ' at ' +
                          dayjs(event.sessionStartDttm).format(format) + ' ' + returnAMPMBasedOnDate(event.sessionStartDttm)
                        }
                          
                        {...(!isPublicView ? {
                          to: `/account/classes/${event.id}`,
                        } : {
                          onClick: () => {
                            console.log('e: onclick')
                            setModalIsOpen(true);
                            setModalSelectedSession(event);
                          }
                        })} 
                        className="font-semibold text-brand-500 hover:text-brand-400 text-lg pb-1 cursor-pointer underline">
                      {eventSession.eventSessionName}
                      </Link>: <button  className="font-semibold text-brand-500 hover:text-brand-400 text-lg pb-1 cursor-pointer underline"
 title={enrolled ? 'Learn more about ' + 
                          eventSession.eventSessionName + 
                          ' class on ' + 
                          dayjs(event.sessionStartDttm).format("dddd") + 
                          ' at ' +
                          dayjs(event.sessionStartDttm).format(format) + ' ' + returnAMPMBasedOnDate(event.sessionStartDttm)
                          : 
                          'Learn more and enroll in ' + 
                          eventSession.eventSessionName + 
                          ' class on ' + 
                          dayjs(event.sessionStartDttm).format("dddd") + 
                          ' at ' +
                          dayjs(event.sessionStartDttm).format(format) + ' ' + returnAMPMBasedOnDate(event.sessionStartDttm)
                        } onClick={() => {
                          console.log('e: onclick')
                          setModalIsOpen(true);
                          setModalSelectedSession(event);
                        }}>{eventSession.eventSessionName}</button>}
                    <p className="text-gray-500 text-sm">
                      {eventSession.eventSessionShortDesc}
                    </p>
                  </div>
                </div>
                </li>
                </ul>
                </li>
              );
            } else return null;
          })}
      </ul>
    );
  };

  const ScheduleSkeletonLoader = () => (
  <div className="my-20">
     <svg className="mx-auto animate-spin h-6 w-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
</svg>
 </div>

  )

  if (!scheduleData) {
    return <ScheduleSkeletonLoader />
  }

  let mytag ='h2'

  if(isPublicView){
    mytag = 'h3'
  }

  const H2h3Tag = `${mytag}`;

 

  const handleToggleCareerStages = (code) => { 
    if(handleToggleCareerStages && selectedCareerStages.includes(code)) {
        // if only one stage is selected, do not allow to uncheck until another is selected
      if(selectedCareerStages && selectedCareerStages.length > 1) {
        setSelectedCareerStages(selectedCareerStages.filter(careerCode => careerCode !== code))
      }
    } else {
      setSelectedCareerStages((selectedCareerStages) => [...selectedCareerStages, code])
    }
  }

  return (
    <div className="mt-2">
       <span className="font-semibold mr-4 sr-only">Filter classes by</span>
 
       <Tab.Group defaultIndex={selectedClassDayFromStorage ? selectedClassDayFromStorage : (autoSelectUpcomingClassDays ? indexOfCurrentDayOrLastItem(scheduleData) : schedulePref)} onChange={(index) => {
       
       if(changeSchedulePref) {
          changeSchedulePref(index)
        }
        setSelectedClassDayFromStorage(index)
         
      }}  >
        <Tab.List className="top-0 z-10 bg-white shadow rounded-lg flex md:inline-block my-3">
          {scheduleData.map((eventDay, d)=> (
            <Tab
            key={d}
            className={({ selected }) =>
              selected
                ? "w-1/2 md:w-auto bg-brand-500 border-brand-500 text-center md:px-5  rounded-lg selected text-white transition-colors"
                : "w-1/2 md:w-auto  border-gray-300 md:px-5 transition-colors text-gray-900 rounded-lg"
                

            }
          >
            <div className="py-3 md:px-5">
            <span className="text-sm md:text-base flex items-center font-semibold justify-center">
            {dayjs(eventDay.eventDayDtlStartDttm).format("dddd, MMMM DD")} <CheckCircleIcon className="hidden w-6 h-6 ml-2 text-white"/>
              </span>
            </div>
          </Tab>
           
          ))}
        </Tab.List>
<div>
 
<b className="mt-2 block">Career Stage</b>
        <div className="my-2 mb-4">
        
        <div className="flex items-center space-y-2 md:space-y-0 md:space-x-2 flex-wrap">
              {careerStages.map((stage) => stage.unavailable ? null : (
             <div key={stage.id} className="w-full md:w-auto focus:border-2 border-red-500">  
                    <input
                    type="checkbox"
                    className="sr-only group"
                    id={`career-stage-${stage.code}`}
                    name={stage.code}
                    value={stage.code}
                    checked={selectedCareerStages && selectedCareerStages.includes(stage.code)}
                    onChange={() => handleToggleCareerStages(stage.code)}
                  />
              <label htmlFor={`career-stage-${stage.code}`}style={{borderColor: selectedCareerStages && selectedCareerStages.includes(stage.code) ? stage.color: "#e5e7eb" }} className={`bg-white border-2 block p-4 font-medium rounded-lg text-left flex items-center p-4 flex items-center hover:bg-white  cursor-pointer`}>
                
                <div  className="flex items-center justify-center h-6 w-6 border-2 mr-2 rounded-full transition ease duration-100 relative" 
                style={{borderColor: stage.color, backgroundColor: selectedCareerStages && selectedCareerStages.includes(stage.code) ? stage.color: "#fff"}} >
                <CheckIcon style={{ stroke: 'white', strokeWidth: '2px'}} className={` h-5 w-5 p-1 text-white ${selectedCareerStages && selectedCareerStages.includes(stage.code) ? 'opacity-100' :'opacity-0'}`}/>
                 

                </div>{stage.name}</label>
              </div>))}

              </div> 
        </div>
        </div>
 
        
        <Tab.Panels className="border-gray-200 border-t-2 mt-6 pt-6">
        {scheduleData.map((eventDay, d)=> {  
          return (
            <Tab.Panel key={eventDay.id}>
                     <ReturnListOfEventByDayAndTrack
                    events={eventDay}
                    tab={1}
                    date={8}
                    sessionTypeCode="SES"
                    sessionCat={[selectedCareerStages]} 
                  />


              </Tab.Panel>
           
          )})}
         
        </Tab.Panels>
      </Tab.Group>
      <Dialog
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        className="fixed z-10 inset-0 overflow-y-auto "
      >
        {/* Use the overlay to style a dim backdrop for your dialog */}
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

        <div className="flex items-center justify-center min-h-screen">
          <div className="relative bg-white rounded-lg max-w-3xl mx-auto p-4">
            <button
              type="button"
              className="mb-3 bg-white rounded-full text-gray-700 hover:text-gray-800  p-3  bg-gray-100 hover:bg-gray-200 focus:outline-none focus:bg-gray-50 absolute top-0 right-0 mt-6 mr-6"
              onClick={() => setModalIsOpen(false)}
            >
              <span className="sr-only">Close</span>
              <XIcon className="h-8 w-8" aria-hidden="true" />
            </button>
            <div className="mt-6 px-6 py-4 pb-6 ">

            <p>
                    {dayjs(modalSelectedSession && modalSelectedSession.sessionStartDttm).format(format)}{returnAMPMBasedOnStartEndDate(modalSelectedSession && modalSelectedSession.sessionStartDttm,modalSelectedSession && modalSelectedSession.sessionEndDttm)[0]} -{" "}
                    {dayjs(modalSelectedSession && modalSelectedSession.sessionEndDttm).format(format)}{returnAMPMBasedOnStartEndDate(modalSelectedSession && modalSelectedSession.sessionStartDttm,modalSelectedSession && modalSelectedSession.sessionEndDttm)[1]}
                  </p>


              
              <Dialog.Title>
                <H2h3Tag className="font-bold text-gray-900 text-2xl md:text-3xl my-3">
                  {modalSelectedSession &&
                    modalSelectedSession.eventSession.eventSessionName}
                </H2h3Tag>
              </Dialog.Title>
              <p className="text-gray-700">
                {modalSelectedSession &&
                  modalSelectedSession.eventSession.eventSessionDesc}
              </p>
            </div>{" "}
            <Button color="secondary" onClick={()=>setModalIsOpen(false)} className="block  mx-auto">Close</Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default ScheduleRegisteredUser;
